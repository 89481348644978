import classNames from "classnames";
import dynamic from "next/dynamic";
import path from "path";
import { CSSProperties, FC, Fragment, ReactNode } from "react";
import Footer from "~/sections/Footer";
import SimpleHeader from "~/sections/Header/Simpleheader";
import { getTheme } from "~/utils/theme";

const QuickCart = dynamic(() => import("~/components/QuickCart"), {
  ssr: false,
});

interface LayoutProps {
  isUseLayout?: boolean;
  children?: ReactNode;
  isUseMinHeight?: boolean;
  contentContainerStyle?: CSSProperties;
  recentKeywords?: string[];
  setRecoilRecentKeyword?: (value: string[]) => void;
  className?: string;
  renderHeader?: ReactNode;
}

const themeHeaderPath = path.join(
  process.cwd(),
  "src/theme",
  getTheme(),
  "sections",
  "Header",
  "Header.tsx"
);

// const Header = dynamic(() =>
//   checkFileExists(themeHeaderPath)
//     ? import(`~/theme/${getTheme()}/sections/Header`)
//     : import("~/sections/Header")
// );

const Header = dynamic(
  async () => {
    try {
      return await require(`~/theme/${getTheme()}/sections/Header`);
    } catch (error) {
      return await require(`~/sections/Header`);
    }
  },
  { ssr: false }
);

const Layout: FC<LayoutProps> = ({
  isUseLayout = true,
  children,
  isUseMinHeight = true,
  contentContainerStyle,
  className = "",
  renderHeader,
}) => {
  if (renderHeader) {
    return (
      <Fragment>
        {renderHeader ? renderHeader : null}

        <main
          className={classNames({ "min-h-screen": isUseMinHeight }, className)}
          style={contentContainerStyle}
        >
          {children}
        </main>
        <Footer />
      </Fragment>
    );
  }

  if (!isUseLayout) {
    return (
      <Fragment>
        {<SimpleHeader />}
        <main
          className={classNames({ "min-h-screen": isUseMinHeight }, className)}
          style={contentContainerStyle}
        >
          {children}
        </main>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {Header ? <Header /> : null}

      <main
        className={classNames({ "min-h-screen": isUseMinHeight }, className)}
        style={contentContainerStyle}
      >
        {children}
      </main>

      <QuickCart />
      <Footer />
    </Fragment>
  );
};

export default Layout;
